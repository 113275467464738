:root {
  --bg-color: #000000;
  --header-height: 54px;
}

body {
  font-family: 'Open Sans', Helvetica, Arial, sans-serif;
  margin: 0;
  padding: 0;
  background-color: var(--bg-color);
  color: #fff;
}
img {
  display: block;
}
a {
  text-decoration: none;
  color: #ff9999;
}
#bgCanvas {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #000;
}
#sideMenu {
  transition: all 0.3s ease-in-out;
  position: fixed;
  width: 200px;
  height: 100%;
  top: 0;
  left: -200px;
  background-color: #333;
  box-shadow: inset -15px 0px 30px -15px rgba(0,0,0,0.7);
}
#page {
  transition: all .3s ease-in-out;;
  position: relative;
  overflow-x: auto;
  overflow-y: auto;
  left: 0;
}
.page-content {
  height: calc(100vh - var(--header-height));
  padding: 0px 16px;
}
.hamburger {
  opacity: 0;
  display: block;
  margin: 6px 12px;
  position: absolute;
  cursor: pointer;
  z-index: 40;
  left: 0px;
  top: -50px;
  transition: all 0.3s ease-in-out;
}
.headerMenu {
  display: block;
}
.headerTable {
  /* display: grid;
  grid-template-columns: 1fr auto 1fr; */
  background-color: rgba(255,255,255,0.25);
  box-shadow: inset 0px 0px 16px rgba(0, 0, 0, 0.25);
  position: relative;
  width: 100%;
  height: var(--header-height);
  z-index: 20;
}
.headerTitle {
  position: relative;
  left: 12px;
  font-size: 130%;
  transition: all 0.3s ease-in-out;
}
.headerButton {
  text-transform: uppercase;
  padding-right: 24px;
  color: #ddd;
  display: inline-block;
  cursor: pointer;
  transition: all 0.4s ease-in-out;
}
.headerButton:hover, .headerButton:active {
  color: #fff;
  text-shadow: 0 0 5px #fff;
}
.menu {
  display: grid;
  grid-template-columns: 1fr auto auto auto 1fr;
  vertical-align: top;
  height: 50px;
}
.menuItem, .menuSpacer, .sideMenuItem {
  background-color: rgba(0,0,0,0.5);
  display: inline-block;
  align-self: self-start;      
  padding: 12px 24px;
  color: #ccc;
  text-transform: uppercase;
  font-size: 80%;
  transition: all 0.2s ease-in-out;
  vertical-align: top;
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.8);
  position: relative;
  z-index: 10;
  cursor: pointer; 
}
.sideMenuItem {
  display: block;
  margin: 2px 0px;
  padding: 16px 16px;
  box-shadow: 0px 0px 0px rgba(0, 0, 0, 0);
}
.menuItem:hover, .menuItem:active {
  background-color: rgba(100,100,100,0.5);
  color: #fff;
  text-shadow: 0 0 5px #fff;
  padding-top: 16px;
  z-index: 11;
}
.sideMenuItem:hover, .sideMenuItem:active {
  background-color: rgba(100,100,100,0.5);
  color: #fff;
  text-shadow: 0 0 5px #fff;
  padding: 20px 16px;
}

.pageLayout {
  display: grid;
  grid-template-columns: auto 1200px auto;
  text-align: center;
}
.main {
  /*background-color: rgba(0,0,0,0.1);*/
  grid-column: 2/3;
  
  display: grid;
  grid-template-columns: minmax(300px,400px) 1fr 1fr minmax(300px,400px);
  grid-auto-flow: dense; /* (this will fill in gaps, rather than skipping them) */
}
.textBox {
  justify-self: center;
  align-self: center;
  color: #ccc;
  font-size: 150%;
  font-style: italic;
  text-shadow: 0 0 12px rgba(0,0,0,0.5);
}
.imgBox {
  background-color: rgba(255,255,255,0.5);
  box-shadow: 0px 0px 16px rgba(0, 0, 0, 0.5);
  margin: 16px;
  transition: all 0.2s ease-in-out;
}
/* .imgBox:hover, .imgBox:active {
  filter: brightness(130%);
  cursor: pointer;
} */
.imgLeft {
  grid-column: 1/4;
}
.textLeft {
  grid-column: 1/2;
  margin: 0px 32px 0px 16px;
}
.imgRight {
  grid-column: 2/5;
}
.textRight {
  grid-column: 4/5;
  margin: 0px 16px 0px 32px;
}
.textFull {
  grid-column: 1 / span 4;
}
.iframeContainer {
  position: relative;
  width: 100%;
  padding-bottom: 56.25%;
  height: 0;
}
.iframeContainer iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
@media (max-width: 1232px) {
  .pageLayout {
    grid-template-columns: 16px auto 16px;
  }
}
@media (max-width: 860px ) {
  .textBox {
    font-size: 120%;
  }
}
@media (max-width: 700px ) { /* mobile layout */
  .hamburger {
    opacity: 1;
    top: 5px;
    left: 0;
  }
  .headerTitle {
    left: var(--header-height);
  }
  .menu {
    display: none;
  }
  .menuSpacer {
    display: none;
  }
  .headerMenu {
    display: none;
  }
  .imgLeft, .imgRight, .textLeft, .textRight {
    grid-column: 1/5;
  }
  .main {
    grid-template-columns: auto auto auto auto;
  }
  .textBox {
    font-size: 120%;
    margin-top: 0px;
    margin-bottom: 32px;
  }
  .imgBox, .textBox {
    margin-left: 0px;
    margin-right: 0px;
  }
}
